/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*this is top section of home.tsx with my face*/
.App-header {
  background-color: #70757a;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.portfolio-item {
  border: lightgray solid 1px;
  border-radius: 2px;
  margin: 5px;
  /* max-width: 300px; */
  width: 300px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem; /*paul added*/
  font-weight: 500;
  line-height: 1.2;
}

/*Paul added to remove clipping on switch*/
.MuiFormControlLabel-root {
  margin-right: 19px;
}

.row {
  margin-left: 15px;
  margin-right: 15px;
}

.auth-container {
  display: var(--container-display);
  justify-content: var(--container-justify);
  align-items: var(--container-align);
  /* min-height: var(--container-height); */
}

/*dupe aws signin button style*/
.awsbutton {
  width: 100%;
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0px;
  font-size: var(--amplify-text-sm);
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  color: var(--color);
  padding: var(--padding);
  letter-spacing: 0.75px;
  text-transform: var(--text-transform);
  background-color: var(--background-color);
  border-width: var(--border-width);
  border-color: var(--border-color);
  border-style: var(--border-style);
}

/*  use as  <div className="responsive-text">*/
.responsive-text {
  padding-left: 10px; /* Default padding */
}

@media (min-width: 768px) {
  .responsive-text {
    padding-left: 20px; /* Tablets */
  }
}

@media (min-width: 1024px) {
  .responsive-text {
    padding-left: 40px; /* Desktops */
  }
}
