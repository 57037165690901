.login__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 50vh;
  }
  
  .login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login__fieldName {
    align-self: flex-start;
    margin-top: 20pt;
  }
  
  .login__inputField {
    margin: 1rem 0 0.25rem;
    width: 15rem;
  }
  
  .login__error {
    font-size: 12px;
    color: red;
  }
  
  .login__submitButton {
    background: white;
    margin: 1.5rem;
    padding: 0.5rem 1rem;
    color: #2196f3;
    border: 1px solid #2196f3;
    border-radius: 10px;
    font-weight: 700;
  
    &:hover {
      background: #2196f3;
      color: white;
    }
  }