.feature-section{
    display: flex; 
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
.section-header {
    width:100%
}

.avatar{
    border-radius: 50%;
    transform: rotate(-9deg);
}

/* entire page*/
.App {
    text-align: center;
    background-color: whitesmoke;
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
}