.bg-myColor {
  background-image: linear-gradient(to right, #26225f, #3d3a6e);
}

.navbar .navbar-brand,
.navbar .navbar-nav .nav-link,
.nav-link .active {
  color: white;
}

.signinButton {
 background-color: transparent !important;
 border: none !important;
 padding:0;
 line-height: 2.75 !important;
 margin-left: 10px;

}
